<template>
    <div class="aviso">
        <div class="menu">
            <b-container class=" align-items-center"> 
                <b-row class="justify-content-center"  >
                <b-col>
                    <b-navbar toggleable="lg" type="light">
                    <b-navbar-brand href="#home"><a :href="$router.resolve({ name: 'home'}).href"> <img src="../assets/images/img_logo_black.svg" id="logo"/></a></b-navbar-brand>
                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                    <b-collapse id="nav-collapse" is-nav v-model="isNavbarExpanded">
                    <b-navbar-nav class="ml-auto align-items-center">
                        <b-nav-item :href="$router.resolve({ name: 'acercade'}).href">Acerca de</b-nav-item>
                        <b-nav-item :href="$router.resolve({ name: 'comofunciona'}).href">¿Cómo funciona?</b-nav-item>
                        <b-nav-item :href="$router.resolve({ name: 'integracion'}).href">Integración</b-nav-item>
                        <b-nav-item :href="$router.resolve({ name: 'modulos'}).href">Módulos</b-nav-item>
                        <b-nav-item :href="$router.resolve({ name: 'contacto'}).href">Contacto</b-nav-item>
                    </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
                </b-col>
                </b-row>
            </b-container>
        </div>
        
        <b-container>
            <b-row>
                <b-col>
                    <h1>Aviso de Privacidad</h1>

                    <h3>Aviso de Privacidad para Usuarios de Página Web</h3>
                    <p>El presente aviso de privacidad tiene como objeto informarle sobre el tratamiento que se le darán a sus datos personales, cuando estos son recabados, utilizados, almacenados y/o transferidos por Safepass, en su calidad de Responsable, con domicilio en Blvd, Calz. Abelardo L Rodriguez S/N, Alamitos, 21210 Mexicali, B.C., México.</p>

                    <p>Los datos personales que fueron proporcionados por usted a través del portal de internet <a href="https://Safepassapp.com">https://Safepassapp.com</a>, serán protegidos conforme a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP), su Reglamento, los Lineamientos del aviso de privacidad, publicados en el Diario Oficial de la Federación el 17 de enero de 2013. El Titular de los datos podrá obtener en cualquier momento, una copia de las leyes y disposiciones antes mencionadas en el domicilio del Responsable por medio del Departamento de Datos Personales o solicitarlo en la siguiente dirección de correo electrónico: <a href="mailto:contacto@safepassapp.com">contacto@Safepassapp.com</a>.</p>

                    <p>Los datos personales que Safepass recaba de usted, los utilizará para las siguientes finalidades que son necesarias para el servicio que solicita:</p>

                    <ul>
                        <li>Contactarlo para atender su petición o pregunta.</li>
                    </ul>

                    <p>Al realizar el envío y/o entrega de su información, usted autoriza el tratamiento de sus datos personales para las finalidades aquí señaladas.</p>

                    <p>Safepass le informa qué datos personales utilizará para llevar a cabo las finalidades descritas en el presente aviso de privacidad: Nombre, número de teléfono y correo electrónico. Todos estos datos son necesarios para cumplir con el servicio solicitado.</p>

                    <p>Safepass recaba y/o utiliza y/o almacena y/o transmite sus datos personales, en la medida en la que la LFPDPPP lo permite; Safepass podrá hacer uso de sus datos personales para otras finalidades, siempre y cuando sean análogas a las antes señaladas o en su caso sea autorizado o solicitado expresamente por usted.</p>

                    <p>Le informamos que Safepass compartirá sus datos personales dentro del país con empresas que forman parte del mismo grupo empresarial de Safepass, asegurándose que dichas empresas mantengan medidas de seguridad, administrativas, técnicas y físicas adecuadas para resguardar sus datos personales, así mismo se hace de su conocimiento que al momento de que dichos terceros reciban sus datos personales adoptarán la figura de Responsable del tratamiento sus datos personales, dichos terceros solo tratarán sus datos de conformidad con el presente aviso de privacidad, esto es, para las finalidades para las cuales fueron recabados, en caso de que se inicie una relación de negocios entre el Titular de los datos y alguna de las empresas que forman parte de Safepass, se le dará a conocer el aviso de privacidad correspondiente.</p>

                    <h3>Uso de Tecnologías de Rastreo en Nuestro Portal de Internet</h3>
                    <p>Safepass le informa que en el portal de internet <a href="https://Safepassapp.com">https://Safepassapp.com</a> no se utilizan web beacons, cookies ni otros rastreadores de navegador, ni almacena cookies de terceros.</p>

                    <p>Sin embargo, cuenta con el código de rastreo de Google Analytics que es una herramienta universal para conocer la conducta del usuario dentro del sitio y proporciona información anónima sobre éste, cabe señalar que esta información se consulta directamente en Google, su información no es almacenada en la plataforma de Safepass. Adicionalmente, proporcionamos los siguientes links que se encuentran en internet, que no son responsabilidad de Safepass pero que pueden ayudarle a consultar todo lo referente respecto al primer y segundo párrafo del presente capitulo:</p>

                    <ul>
                        <li><a href="http://www.cookie-checker.com/">http://www.cookie-checker.com/</a></li>
                        <li><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></li>
                    </ul>
                    
                    <h3>Cambios y Modificaciones al Aviso de Privacidad</h3>
                    <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, de nuevos proyectos, de nuestras prácticas de privacidad, o por otras causas.</p>

                    <p>En caso de existir cambios o modificaciones en el aviso de privacidad nos comprometemos a mantenerlo informado sobre estos. Si diera un cambio o actualización al presente aviso Safepass notificará dicho cambio, en los siguientes medios:</p>

                    <ul>
                        <li>En los domicilio ubicado en Blvd, Calz. Abelardo L Rodriguez S/N, Alamitos, 21210 Mexicali, B.C., México.</li>
                        <li>En la página de internet <a href="https://Safepassapp.com">https://Safepassapp.com</a></li>
                    </ul>
                    <p>Safepass está comprometido con la protección de sus datos personales, por lo cual ha implementado políticas de seguridad y de privacidad de datos personales acorde con las obligaciones contenidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>

                    <p>Le informamos que usted puede acudir en cualquier momento al Instituto Federal de Acceso a la Información y Protección de Datos si considera que su derecho a la protección de datos ha sido vulnerado.</p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>
.aviso{
    text-align: left;
    padding-bottom: 10%;
}
a{
    text-decoration: none;
    color: #000000;
}

.menu{
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  position: fixed;
  max-width: 100%;
  z-index: 4;
  width: 100%;
  background-color: #F7F9FC;
 
}
a.nav-link{
  font-weight: 400;
  font-size: 18px;
  line-height: 24.59px;
  color:#000000;
}

.nav-item{
  margin-right: 10px;
}
.ml-auto{
  margin-left: auto;
}

h1{
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 55px;
    margin-top: 150px;
    margin-bottom: 50px;
    color: #000 !important;
}

h2{
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
}

h3{
    font-weight: 400;
    font-size: 30px;
    line-height: 50.54px;
}

h4{
    font-weight: 400;
    font-size: 25px;
    line-height:34.15px;
}
 
h5{
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 35px;
    line-height:43.75px;
    color: #FFFFFF;
    border-radius: 100px;
        background-color: #000000;
        padding: 10px 20px;
        width: 65px;
        position: relative;
        bottom: 4vh;
        margin: 0 auto; 
        
}

.cardstep{
        border: 2px solid #34DBA1;
        padding:  0px 25px 45px 25px;
        border-radius: 30px;
        margin-right: 12px;
    }

p{
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
}

h6{
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
}

.h7{
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
    color: #FFFFFF;
}
    .intro .slick-dots , .slick-dots{
        bottom: 40px;
        width: auto;
        left: 7%;
    }
    .intro .slick-dots li,li{
        margin: 3px;
    }
    .intro .slick-dots li button:before{
        color: white!important;
        font-size: 9px;
    }

    
 
    .intro{
        width: 100%;
    }
    .slide{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        width: 100%;
        height: 92vh!important;

    }
    #slide1{
        background-image: url('../assets/images/img_banner1.png');
    }  
    #slide2{
        background-image: url('../assets/images/img_banner2.png');
    } 
    #slide3{
        background-image: url('../assets/images/img_banner3.png');
    }
    #slide4{
        background-image: url('../assets/images/img_banner4.png');
    }  
    .slide .content{
        display: flex;
        align-items: center;
    } 
    .slide .content div{
        height: auto!important;
    }
    h1{
        color: white;
    
        max-width: 530px;
    }
    span{
        font-weight: 700;
    }

    .r{
       font-size: 18px;
    }
 
    img{
        width: 100%;
    }
    .enviar{
        background-color: #222222!important;
        border: none;
        border-radius: 50px;
 
        color: #FFFFFF;
 
        margin-top: 20px;
        padding-top: 25px;
    }

    .enviar2{
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        top: -1.5vh;
        position: relative;
        color: #FFFFFF;
        background-color: transparent;
        border: none;
    }




    .como{
        background-color: #00B09C!important;
        border: none;
        border-radius: 100px;
        padding: 20px 45px;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: #FFFFFF;
        position: relative;
        top: 30vh;
        left: -7vw;
    }

    .quees{
        border-radius: 50px;
        background-color: #DFF2F2;
        padding: 220px 78px;
        margin-left: -20px;
        margin-right: 20px;
    }

    .queesimg{
        background-image: url('../assets/images/img_phone.png');
        background-repeat: no-repeat;
        border-radius: 50px;
        background-size: cover;
        background-position: center;
        min-height: 100vh;
    }

    .queesbtn{
        background-color: #000000!important;
        border: none;
        border-radius: 100px;
        padding: 15px 35px;
        width: fit-content;
        line-height: 20px;
        color: #FFFFFF;
        font-weight: 700;
       
    }
 
 
    .cardstep img{
        max-width: 135px;
        margin-top: 20px;
    }
 
  .integracion{
    margin-top: 7vh;
  }

    .integracion img{
        max-width: 107px;
        margin-bottom: 10px;
    }

.safepass-active{
    background-color: #34DBA1;
    border-radius: 50px;
    background-image: url('../assets/images/Mask group.svg');
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
}
 
.safeaccess-active{
    background-color: #39877B;
    border-radius: 50px;
    background-image: url('../assets/images/Mask group.svg');
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
}

.safecommunity-active{
    background-color: #30AC9D;
    border-radius: 50px;
    background-image: url('../assets/images/Mask group.svg');
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
}

.integracion button{
    background-color: transparent;
    border: 1px solid #222222;
    border-radius: 100px;
    padding: 10px 45px;
    font-weight: 400;
    font-size: 20px;
 
    color: #222222;
}

.integracion .active{
    background-color: #222222;
    color: #FFFFFF;
    font-weight: 700;
}
.modulos{
    padding-top: 5vh;
}
.modulocard{
    border-radius:30px ;
    margin-right: 20px;
    padding: 70px 64px;
}

.inte{
    padding: 4vh 14vw;
}
.modulos h4{
    font-weight: 700;
    margin-top: 18px;
}
.modulos img{
    max-width: 150px;
}

.video{
    margin-top: 5vh;
    height: 80vh;
    background-color: #39877B;
    border-radius:30px ;
}

.disponible{
    background-color: #222222;
    border-radius: 50px;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.footer{
    background-image: url('../assets/images/img_footerbg.svg');
    background-repeat: no-repeat;
    border-radius:50px ;
    background-size: cover;
    padding: 16vh 18vw;
}


.form-floating > label {
    line-height: 18px;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
    padding-left: 22px;
  }

  .form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after{
      background: transparent;
      color: #FFFFFF;
      padding-left: 22px;
  }

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    padding-left: 22px;
      color: #FFFFFF;
      font-weight: 700;
      border: none;
      font-size: 12px;
      line-height: 14px;
 
  }

  
  input:focus,
  input:active{
      outline: none ;
      margin-bottom: 0px;
      box-shadow: none ;
      border: none;
  }

 #banner{
    padding-top:20vh;
    padding-bottom: 6vh;
 }

  .form-floating{
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    line-height: 18px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 18px;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
  }
  .form-floating > .form-control{
    height: 52px;
    font-family: 'Manrope';
    color: #FFFFFF;
    border:none;
    background-color: transparent ;
    line-height: 24.59px;
    font-size: 18px;
    padding-left: 22px;
    font-weight: 400;
  }

  .hide{
    display: none;
  }
  .form-control{
    background-color: transparent ;
    color: #FFFFFF ;
    margin-bottom: -5px;
    border: none;
  }

.terminos{
    color: #000000;
    font-size: 16px;
}

 


@media(max-width: 1200px){

    #banner{
        padding-top: 10vh;
    }

    #banner{
        padding-bottom: 1vh;
    }

    .como{
        top: 38vh;
        left: -9vw;
    }

    h1{
        top: 34vh;
        left: 2.5vw;
        font-size:45px;
        line-height: 45px; 
 
    }
    .quees{
        padding: 180px 30px;
    }
    .inte{
    padding: 7vh 7vw;
    height: 90vh !important;
    }

    h5{
        left: -1vw;
    }

    .modulos .col{
    padding: 60px 44px;
    }

    .footer{
        padding: 14vh 13vw;
    }

    .slide{
        height: 100vh !important;
    }

}

@media(max-width: 1051px){
    .hide{
        display: flex;
    }

    .quees, .queesimg{
        width: 100%;
    }

    .quees{
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .queesimg{
        background-size: contain;
    }

    .como{
        left: -11vw;
    }
}

@media(max-width: 990px){
    .inte{
    padding: 7vh 1vw;
    height: 95vh !important;
    }

    .apps{
        height: 60vh !important;
    }

    #banner  {
        padding-bottom: 10vh;
        padding-top: 20vh;
    }
  
    h1 {
        font-size: calc(55px * .9);  
        line-height: calc(55px * 0.9);
        max-width: 500px;
        top: 38vh;
    }


    .como{
        left: -10vw;
        top: 42vh;
    }

    #slide1{
            background-image: url('../assets/images/img_banner1_mobil.png');
            border-radius:100px;
            background-size: cover;
        }  
        #slide2{
            background-image: url('../assets/images/img_banner2_mobil.png');
            border-radius:100px;
            background-size: cover;
        } 
        #slide3{
            background-image: url('../assets/images/img_banner3_mobil.png');
            border-radius:100px;
            background-size: cover;
        }
        #slide4{
            background-image: url('../assets/images/img_banner4_mobil.png');
            border-radius:100px;
            background-size: cover;
        }  




}

@media (max-width: 840px) {

    .como{
        left: -13vw;
        top: 42vh;
    }

}
@media (max-width: 768px) {
 

    h2 {
        font-size: calc(45px * 0.9);
        line-height: calc(50px * 0.9);
    }

    h3 {
        font-size: calc(37px * 0.9);
        line-height: calc(50.54px * 0.9);
    }

    h4 {
        font-size: calc(25px * 0.9);
        line-height: calc(34.15px * 0.9);
    }

    h5 {
        font-size: calc(35px * 0.9);
        line-height: calc(43.75px * 0.9);
    }

    h6 {
        font-size: calc(20px * 0.9);
        line-height: calc(27.32px * 0.9);
    }

    .h7 {
        font-size: calc(30px * 0.9);
        line-height: calc(50px * 0.9);
    }

    a.nav-link {
        font-size: calc(18px * 0.9);
    }
 

    .form-floating > label, .form-floating > .form-control, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
        font-size: calc(18px * 0.9);
    }


    .como{
        left: -15vw;
        top: 42vh;
    }

    .quees {
        padding: 100px 20px;
    }

    .queesimg  {
         background-size: cover;  
    }

    .row.pt-5.mt-5.pb-5.mb-3{
        padding-top: 3vh !important;
        padding-bottom:2vh !important;
    }
    .inte {
        padding: 7vh 1vw;
        height: fit-content !important;
    }

    .disponible img{
        max-width: 250px !important;
    }

    .disponible .apps2{ 
        max-width: 173px !important;
    }

    .modulocard {
    
    padding: 30px 34px;
}

 
 
    .footer  {
        padding: 14vh 8vw;
        background-position: center;
    }

    .terminos p{
        text-align: center !important;
    }
 
}


@media(max-width: 600px){
    .como{
        left: 0vw;
        top: 35vh;
    }
    h1 {
        font-size: calc(55px * .7);  
        line-height: calc(55px * 0.7);
        max-width:280px;
        top: 30vh;
        left: 4vw;
    }
   

    h3  {
        font-size: calc(37px * 0.7);
        line-height: calc(50.54px * 0.7);
    }

    
 

    
}
</style>
