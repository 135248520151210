<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>

export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
#app {
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #000000;
  text-decoration: none;
  background-color: #F7F9FC;
 
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
 

.slick-slider  .slick-dots {
  padding-left: 2.5vw;
}
 
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 7px;
    padding: 0;
}.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 3.5px;
    color: transparent;
    border: 1px solid #FFFFFF;
    outline: none;
    background: transparent;
}.slick-dots li, .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    cursor: pointer;
}

    .slick-dots.custom-dot-class{
      display: flex;
       
      bottom: 5vh;
    }

    .slick-dots .slick-active button{
  background: #FFFFFF;
}
.modal-content {
  background: transparent !important;
  border: none !important;
}

.modal-footer,
.modal-header{
  display: none !important;
}

.modal video{
  max-height: 90vh;
  max-width: 100%;
}

.modal{
  text-align: center;
}

@media(max-width: 1400px){

  .slick-dots.custom-dot-class{
 
       
 bottom: 8vh;
}
}
@media(max-width: 1200px){

  .slick-dots.custom-dot-class{
 
       
      bottom: 20vh;
    }

}
@media(max-width: 990px){

.slick-dots.custom-dot-class{

     
    bottom: 12vh;
  }

  .slick-slider  .slick-dots {
  padding-left: 3vw;
}

 
 
}

@media(max-width: 600px){
  .slick-slider  .slick-dots {
  padding-left: 5vw;
}
}
</style>
