import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '../components/HelloWorld'
import AvisoPrivacidad from '../components/AvisoPrivacidad'
import ThanksPage from '../components/ThanksPage.vue'

Vue.use(Router)


export default new Router({
  mode: 'history',
  routes: [
    {
        path: '/',
        name: 'home',
        component: HelloWorld 
    },
    {
      path: '/avisoprivacidad',
      name: 'avisoprivacidad',
      component: AvisoPrivacidad
    },
    {
      path: '/#acercade',
      name: 'acercade',
      component: HelloWorld 
  },
  {
    path: '/#comofunciona',
    name: 'comofunciona',
    component: HelloWorld 
},
{
  path: '/#integracion',
  name: 'integracion',
  component: HelloWorld 
},
{
  path: '/#modulos',
  name: 'modulos',
  component: HelloWorld 
},{
  path: '/#contact',
  name: 'contacto',
  component: HelloWorld 
},{
  path: '/gracias',
        name: 'ThanksPage',
        component: ThanksPage
}
  ]
})

